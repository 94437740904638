@font-face {
  font-family: "Bebas Neue", sans-serif;
  src: url("/public/BebasNeue-Regular.ttf");
}

.canvas {
  width: 100%;
  height: 100vh;
  /* background-color: #1d2125; 
  background-image: linear-gradient(180deg, #1d2125 80%, #ff4141 100%); */
}

.flex {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  /* background-color: #1d2125; */
}

/* section {
  color: #fff;
  height: calc(100vh + 53px);
} */

.footer {
  font-family: "Bebas Neue";
  letter-spacing: 0.1em;
  height: 3vh;
  font-size: 1.3em;
  top: 50%;
  right: 0%;
  left: 0%;
  bottom: 50%;
  color: #fff;
  width: 100%;
  text-align: center;
  background-color: #1d2125; 
  /* background-image: linear-gradient(160deg, #ff4141 0%, #1d2125 15%, #1d2125 50%, #1d2125 65%, #ff4141 100%); */
}
