@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
/* nav {
  top: 0;
  left: 0;
  position: absolute;
  background: #1d2125;
  border-bottom: 3px solid #ff4141;
  height: 50px;
  text-align: left;
  width: 100%;
  z-index: 99;
} */


.scrollSticky {
  z-index: 100;
  position: sticky;
  top: -1px;
}

.topnav {
  background: #1d2125;
  max-height: 100px;
  border-bottom: 3px solid #ff4141;
  font-weight: lighter;
  overflow: hidden;
  transition: all 0.5s;
}

.topnav a {
  /* float: left;
  display: block;
  color: white;
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
  font-size: 17px; */
  text-decoration: none;
  cursor: pointer;
  color: white;
  /* margin: 0 20px; */
  padding: 12px 20px;
  transition: 0.4s;
  font-family: "Bebas Neue";
  font-weight: 400;
  font-size: 2em;
}

.navButton {
  background-color: transparent;
  border: none;
  text-decoration: none;
  cursor: pointer;
  color: white;
  /* margin: 0 20px; */
  padding: 12px 20px;
  transition: 0.4s;
  font-family: "Bebas Neue";
  font-weight: 400;
  font-size: 1.5em;
}
.activeNav {
  background-color: #ff4141;
  color: white;
}

.topnav .mobileButton {
  display: none;
  background-color: transparent;
  border: none;
  color: white;
  padding: 16px 16px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}

.topnav.responsive {
  transition: all 0.5s ease;
  max-height: 500px;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child){
    display: none;
  }
  .topnav .mobileButton {
    display: block;
    float: right;
    color: white;
  }

  .topnav.responsive {
    position: relative;
  }

  .topnav.responsive .mobileButton {
    position: absolute;
    right: 0;
    top: 0;
  }

  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

}
