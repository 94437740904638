@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* nav {
  top: 0;
  left: 0;
  position: absolute;
  background: #1d2125;
  border-bottom: 3px solid #ff4141;
  height: 50px;
  text-align: left;
  width: 100%;
  z-index: 99;
} */


.scrollSticky {
  z-index: 100;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
}

.topnav {
  background: #1d2125;
  max-height: 100px;
  border-bottom: 3px solid #ff4141;
  font-weight: lighter;
  overflow: hidden;
  transition: all 0.5s;
}

.topnav a {
  /* float: left;
  display: block;
  color: white;
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
  font-size: 17px; */
  text-decoration: none;
  cursor: pointer;
  color: white;
  /* margin: 0 20px; */
  padding: 12px 20px;
  transition: 0.4s;
  font-family: "Bebas Neue";
  font-weight: 400;
  font-size: 2em;
}

.navButton {
  background-color: transparent;
  border: none;
  text-decoration: none;
  cursor: pointer;
  color: white;
  /* margin: 0 20px; */
  padding: 12px 20px;
  transition: 0.4s;
  font-family: "Bebas Neue";
  font-weight: 400;
  font-size: 1.5em;
}
.activeNav {
  background-color: #ff4141;
  color: white;
}

.topnav .mobileButton {
  display: none;
  background-color: transparent;
  border: none;
  color: white;
  padding: 16px 16px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}

.topnav.responsive {
  transition: all 0.5s ease;
  max-height: 500px;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child){
    display: none;
  }
  .topnav .mobileButton {
    display: block;
    float: right;
    color: white;
  }

  .topnav.responsive {
    position: relative;
  }

  .topnav.responsive .mobileButton {
    position: absolute;
    right: 0;
    top: 0;
  }

  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

}

@font-face {
  font-family: "Bebas Neue", sans-serif;
  src: url("/public/BebasNeue-Regular.ttf");
}

#about {
  padding: 2em;
  /* background-image: linear-gradient(to right, #a62d2d, #3E5151, #1d2125); */
  background-image: linear-gradient(to right, #434343 0%, #1d2125 100%);
}

#skills {
  padding-top: 2em;
  padding-left: 4em;
  padding-right: 4em;
  background-image: linear-gradient(to right, #434343 0%, #1d2125 100%);
  /* background-image: linear-gradient(to right, #a62d2d, #3E5151, #1d2125); */
}

#projects {
  padding-top: 2em;
  padding-left: 4em;
  padding-right: 4em;
  background-image: linear-gradient(to right, #434343 0%, #1d2125 100%);
}

#contact {
  padding-top: 2em;
  padding-left: 4em;
  padding-right: 4em;
  background-image: linear-gradient(to right, #434343 0%, #1d2125 100%);
}

.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10vmax;
  padding-right: 10vmax;
  padding-bottom: 5vmax;
}

label {
  font-family: "Bebas Neue", sans-serif;
  font-size: 2em;
}

input {
  width: 100%;
  padding: 1vw;
  font-size: medium;
}

textarea {
  width: 100%;
  height: 10vw;
  padding: 1vw;
  min-height: 10vw;
  resize: vertical;
  font-size: large;
}

.headings {
  /* color: #ff4141; */
  font-family: "Bebas Neue";
  font-size: 4em;
  font-weight: 400;
  letter-spacing: .2rem;
  text-align: center;
  margin: 15px;
  width: 100%;
}
.content_wrapper {
  width: 100%;
  background-color: #363636;
  /* border-top: 3px solid #ff4141; */
  align-items: center;
  justify-content: center;
}

.flex-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  box-sizing: border-box;
  /* margin: 8em;
  padding: 4em; */
  margin-top: 0;
  max-width: calc(6*(220px));
}

.skill {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 10rem;
  color: #fff;
  justify-content: center;
  align-items: center;

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding-left: 1rem;
  padding-right: 1rem; */
}

.skillName {
  text-align: center;
  font-size: 2.5em;
  font-family: "Bebas Neue";
  margin: 0;
  font-weight: 300;
}

.skillImg {
  width: 150px;
  height: 150px;
}

.vulkanImg {
  width: 650px;
  height: 250px;
}

.skillFlex {
  box-sizing: border-box;
  /* display: flex; */
  position: relative;
  padding: 40px;
  margin: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projectsWrapper {
  width: 90%;
  margin: 0 auto;
  max-width: 80rem;
  box-sizing: border-box;
  padding: 0;
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.item {
  width: calc(50% - 2rem);
  margin: 1rem;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
}

.projectContainer {
  /* display: flex; */
  /* flex-wrap: wrap; */
  padding: 0;
  margin: 0;
  -webkit-perspective: 500px;
          perspective: 500px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  box-sizing: border-box;
}

.projectContainer:hover .front, .projectContainer:hover .back {
  transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  
}

.front, .back {
  background-size: cover;
  box-shadow: 0 1px 1px rgb(0 0 0 / 15%), 0 2px 2px rgb(0 0 0 / 15%), 0 4px 4px rgb(0 0 0 / 15%), 0 8px 8px rgb(0 0 0 / 15%);
  text-align: center;
  background-position: center;
  min-height: 420px;
  height: auto;
  color: white;
  font-size: 1.5rem;
	transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.front:after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  background-color: #000;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: .3;
}

.back{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #cedce7;
  background-color: #1d2125;
  /* transition: 1s; */
/* background-image: linear-gradient(62deg, #a62d2d 0%, #1d2125 100%); */

}

.projectContainer .back{
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.projectContainer .front{
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.projectContainer:hover .back{
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
          box-shadow: 0 1px 1px rgb(255 65 65 / 15%), 0 2px 2px rgb(255 65 65 / 15%), 0 4px 4px rgb(255 65 65 / 15%), 0 8px 8px rgb(255 65 65 / 15%);
}

.projectContainer:hover .front{
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.inner {
  -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
            transform: translateY(-50%) translateZ(60px) scale(0.94);
    top: 85%;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    outline: 1px solid transparent;
    -webkit-perspective: inherit;
            perspective: inherit;
    z-index: 2;
}

.back .inner {
  -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
  transform: translateY(-50%) translateZ(60px) scale(0.94);
top: 50%;
position: absolute;
left: 0;
width: 100%;
padding: 2rem;
box-sizing: border-box;
outline: 1px solid transparent;
-webkit-perspective: inherit;
  perspective: inherit;
z-index: 2;

}

.back .inner p{
  font-size: 1rem;
  margin-bottom: 2rem;
  position: relative;
}


.front .inner p{
  font-size: 2rem;
  margin-bottom: 2rem;
  position: relative;
}

.front .inner p:after{
  content: '';
  width: 4rem;
  height: 2px;
  position: absolute;
  background: #C6D4DF;
  display: block;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -.75rem;
}

.front .inner span{
  color: white;
  font-family: "Bebas Neue";
  font-size: 2.5rem;
  font-weight: 300;
}

.gitContainer {
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
}

.gitButton {
  position: relative;
  display: block;
  margin: 30px auto 0;
  padding: 14px 45px;
  color: #fff;
  font-size: 14px;
  border: 2px solid #D24D57;
  border-radius: 0;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  letter-spacing: .08em;
  text-shadow: 0 0 1px rgb(0 0 0 / 20%), 0 1px 0 rgb(0 0 0 / 20%);
  transition: all 1s ease;
}

.gitButton::after{
  background: #d24d57;
  opacity: .5;
  -webkit-transform: translateY(-50%) translateX(-50%) rotate(90deg);
          transform: translateY(-50%) translateX(-50%) rotate(90deg);
  content: "";
  position: absolute;
  height: 0%;
  left: 50%;
  top: 50%;
  width: 150%;
  z-index: -1;
  transition: all .75s ease 0s;
}

.gitButton:hover::after {
  opacity: 1;
	height: 600% !important;
}

.btn {
  box-sizing: border-box;
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 2px solid #D24D57;
  border-radius: 0.6em;
  color: white;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  /* margin: 20px; */
  padding: 1em 2.4em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: "Bebas Neue";
  font-weight: 300;
  font-size: 1.8rem;
}

.btn:hover, .btn:focus {
  color: white;
  outline: 0;
}

.submitButton {
  border-color: #D24D57;
  border-radius: 0;
  color: white;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-shadow: 0 0 1px rgb(0 0 0 / 20%), 0 1px 0 rgb(0 0 0 / 20%);
  transition: all 1s ease;
}

.submitButton::after {
  content: '';
  position: absolute;
  display: block;
  opacity: .5;
  -webkit-transform: translateY(-50%) translateX(-50%) rotate(90deg);
          transform: translateY(-50%) translateX(-50%) rotate(90deg);
  height: 0%;
  left: 50%;
  top: 50%;
  width: 150%;
  background: #D24D57;
  z-index: -1;
  transition: all .75s ease 0s;
}

.submitButton:hover {
  color: white;
}

.submitButton:hover::after {
  width: 110%;
  opacity: 1;
	height: 600% !important;
}


/* .submitButton::after{
  background: #d24d57;
  opacity: .5;
  transform: translateY(-50%) translateX(-50%) rotate(90deg);
  content: "";
  position: absolute;
  height: 0%;
  left: 50%;
  top: 50%;
  width: 150%;
  z-index: -1;
  -webkit-transition: all .75s ease 0s;
  -moz-transition: all .75s ease 0s;
  -o-transition: all .75s ease 0s;
  transition: all .75s ease 0s;
} */


/* .projectContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 80px;
  perspective: 500px;
  transition: transform 300ms ease-in;
  box-sizing: border-box;
} */


img {
  max-width: 100%;
  max-height: 100%;
  /* transition: transform .2s; */
}

.projectImage {
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cardflex {
  box-sizing: border-box;
  display: flex;
  position: relative;
  padding: 40px;
  transition: 0.25s;
  box-shadow: 0 1px 1px rgb(0 0 0 / 15%), 0 2px 2px rgb(0 0 0 / 15%), 0 4px 4px rgb(0 0 0 / 15%), 0 8px 8px rgb(0 0 0 / 15%);
  background-color: #1d2125;
  flex-direction: column;
}

.cardflex:hover {
  box-shadow: 0 1px 1px rgb(255 65 65 / 15%), 0 2px 2px rgb(255 65 65 / 15%), 0 4px 4px rgb(255 65 65 / 15%), 0 8px 8px rgb(255 65 65 / 15%);
  transition: 0.25s;
  flex-direction: column;
}

.cardflex:hover .abouticons {
  text-shadow: 0 1px 1px rgb(255 65 65 / 15%), 0 2px 2px rgb(255 65 65 / 15%), 0 4px 4px rgb(255 65 65 / 15%), 0 8px 8px rgb(255 65 65 / 15%);
}

.abouticons {
  color: white;
  font-size: 5rem;
  position: relative;
  text-align: center;
  /* color: #ff4141; */
}

.abouticons h3 {
  font-size: 2rem;
  color: white;
  position: relative;
  text-align: center;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  
  /* color: #ff4141; */
}

.icon-gamepad3:before {
  content: "\e96d";
}

.icon-lamp7:before {
  content: "\ebf9";
}

.icon-cup2:before {
  content: "\ebc9";
}

p {
  font-size: 1.2rem;
  color: white;
}

.flex {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.headers {
  font-family: "Bebas Neue";
  font-size: 3.5em;
  /* margin: 16px; */
}

.skillDescription {
  font-size: 1.2em;
  text-align: left;
}

/* Tab List CSS */
.tabs {
  text-align: center;
  width: 90%;
  color: white;
}

.tab-list {
  border-bottom: 1px solid #ccc;
  width: 100%;
  padding-left: 0;
}

.tab-list-item {
  cursor: pointer;
  font-family: "Bebas Neue";
  font-weight: 400;
  font-size: 1.8em;
  color: white;
  display: inline-block;
  list-style: none;
  padding: 0.5rem 0.75rem;
}

.tab-list-active {
  color: #ff4141;
}

/* Tab Content CSS */
.tab-content {
  display: flex;
  flex-direction: column;
  min-height: 60vh;
  margin: 20px auto;
}


@media (max-width: 950px) {
  #projects {
    padding-top: 2em;
    padding-left: 2em;
    padding-right: 2em;
    background-image: linear-gradient(to right, #434343 0%, #1d2125 100%);
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  .item {
    width: 100%;
    margin: 1rem;
    padding: 0;
    box-sizing: border-box;
    cursor: pointer;
  }
  
}

@media (max-width: 950px) {
  #projects {
    padding-top: 2em;
    padding-left: 0em;
    padding-right: 0em;
    background-image: linear-gradient(to right, #434343 0%, #1d2125 100%);
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  .item {
    width: 100%;
    margin: 0.5rem;
    padding: 0;
    box-sizing: border-box;
    cursor: pointer;
  }
  
}
.aboutSection {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  /* grid-template-columns: repeat(3, minmax(300px, 1fr)); */
  /* grid-auto-columns: 200px; */
}

/* .skillType {
  grid-column: 1;
  background-color: #1d2125;
  padding: 10px;
}

.skillList {
  grid-column: 2 / 4;
  background-color: #1d2125;
  padding: 10px;

} */

.skillSection {
  --n: 4;
  --repeat: auto-fit;
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(max(200px, 45vw),4fr));
  /* grid-template-columns: repeat(var(--repeat, auto-fit), minmax(calc(250px * 1) , 1fr)); */
}

.skills {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  /* grid-auto-rows: 340px; */
}

.skillsWrapper {
  display: grid;
  grid-auto-rows: minmax(100px, auto);
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}
.projects{
  display: grid;
  /* grid-auto-rows: minmax(100px, auto); */
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}

@media (max-width: 600px) {
  

  .skillsWrapper {
    display: grid;
    grid-auto-rows: minmax(100px, auto);
    grid-template-columns: 100%;
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }
  .skillType {
    grid-column: 1;
    background-color: #1d2125;
    padding: 10px;
  }

  .skillList {
    grid-column: 1;
    background-color: #1d2125;
    padding: 10px;
  }

  .skillSection {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    grid-template-columns: none;
  }
  
}

@media (max-width: 1280px) {
  /* .aboutSection {
    display: grid;
    grid-template-columns: none;
    grid-column: 1;
  } */

  /* .skillSection {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, minmax(300px, 1fr))
  } */

  /* .projects{
    display: grid;
    grid-template-columns: none;
    grid-column: 1;
    row-gap: 10px;
    } */
}

.social-wrapper {
  display: flex;
  position: absolute;
  top: 90vh;
  width: 100%;
  text-align: center;
  pointer-events: none;
}

.links {
  display: flex;
  justify-content: center;
  width: 100%;
}

.centerIcons {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 5px;
}

.icon {
  border: 0.11vmax solid white;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  pointer-events: auto;
  color: white;
  width: 2vmax;
  height: 2vmax;
  font-size: 2vmax;
  padding: 0.35vmax;
  margin: 0;
  display: flex;
  transition: all 0.5s;
}

.icon:hover {
  background-color: #ff4141;
}

@font-face {
  font-family: "Bebas Neue", sans-serif;
  src: url("/public/BebasNeue-Regular.ttf");
}

.canvas {
  width: 100%;
  height: 100vh;
  /* background-color: #1d2125; 
  background-image: linear-gradient(180deg, #1d2125 80%, #ff4141 100%); */
}

.flex {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  /* background-color: #1d2125; */
}

/* section {
  color: #fff;
  height: calc(100vh + 53px);
} */

.footer {
  font-family: "Bebas Neue";
  letter-spacing: 0.1em;
  height: 3vh;
  font-size: 1.3em;
  top: 50%;
  right: 0%;
  left: 0%;
  bottom: 50%;
  color: #fff;
  width: 100%;
  text-align: center;
  background-color: #1d2125; 
  /* background-image: linear-gradient(160deg, #ff4141 0%, #1d2125 15%, #1d2125 50%, #1d2125 65%, #ff4141 100%); */
}

@font-face {
  font-family: "Bebas Neue", sans-serif;
  src: url("/public/BebasNeue-Regular.ttf");
}
.panel {
  display: block;
  position: absolute;
  pointer-events: none;
  text-align: left;
  background-color: transparent;
  -webkit-transform: translate(1vw, -68vh);
          transform: translate(1vw, -68vh);
  line-height: normal;
}
.yesTouch {
  pointer-events: all;
}
.mainIntro {
  font-family: "Bebas Neue";
  font-size: 5vmax;
  font-weight: 400;
  margin: 0;
  /* pointer-events: none; */
}

.subIntro {
  font-family: "Bebas Neue";
  font-size: 3vmax;
  font-weight: 400;
  margin: 0;
}

.mainIntroMobile {
  font-family: "Bebas Neue";
  font-size: 3em;
  margin: 0;
  display: flex;
}

.subIntroMobile {
  font-family: "Bebas Neue";
  font-size: 2em;
  font-weight: bold;
  margin: 0;
  display: flex;
}

@media (max-width: 500px) {
  .mainText {
    display: none;
    pointer-events: none;
  }
  .mobileText {
    display: contents;
  }
}

@media (min-width: 501px) {
  .mainText {
    display: contents;
    pointer-events: none;
  }
  .mobileText {
    display: none;
  }
}

.textColor {
  color: #ff4141;
}

body {
  background-color: #1d2125; 
  background-image: linear-gradient(160deg, #1d2125 10%, #ff4141 100%);
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #1c1b1b;
  line-height: 1.65;
}

.yesTouch {
  pointer-events: all;
}

.noTouch {
  pointer-events: none;
}

.Button_Wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  margin-top: 50px;
}
.ButtonAnimation_Item {
  margin-top: 1em;
}
.ButtonAnimation {
  position: relative;
  text-decoration: none;
  /* display: inline-block; */
  padding: 0.75vmax 28px;
  line-height: normal;
  border: 1px solid transparent;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 0.75vmax;
  text-align: center;
  letter-spacing: 0.2em;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-style: normal;
  background-color: transparent;
}
.ButtonAnimation::before {
  position: absolute;
  content: "";
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  -webkit-transform-origin: left center;
          transform-origin: left center;
  z-index: -1;
}
.ButtonAnimation:not([disabled]):hover::before {
  -webkit-transform-origin: right center;
          transform-origin: right center;
  -webkit-transform: scale(0, 1);
          transform: scale(0, 1);
}
.ButtonAnimation:not([disabled])::before {
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.45s;
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 0s;
}
#section-header .ButtonAnimation {
  color: #363636;
  border-color: #ffffff;
}
#section-header .ButtonAnimation::before {
  background-color: #ffffff;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
  #section-header .ButtonAnimation:hover {
    color: #ffffff;
  }
}

