.social-wrapper {
  display: flex;
  position: absolute;
  top: 90vh;
  width: 100%;
  text-align: center;
  pointer-events: none;
}

.links {
  display: flex;
  justify-content: center;
  width: 100%;
}

.centerIcons {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 5px;
}

.icon {
  border: 0.11vmax solid white;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  pointer-events: auto;
  color: white;
  width: 2vmax;
  height: 2vmax;
  font-size: 2vmax;
  padding: 0.35vmax;
  margin: 0;
  display: flex;
  transition: all 0.5s;
}

.icon:hover {
  background-color: #ff4141;
}
