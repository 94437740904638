@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");

body {
  background-color: #1d2125; 
  background-image: linear-gradient(160deg, #1d2125 10%, #ff4141 100%);
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #1c1b1b;
  line-height: 1.65;
}

.yesTouch {
  pointer-events: all;
}

.noTouch {
  pointer-events: none;
}

.Button_Wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  margin-top: 50px;
}
.ButtonAnimation_Item {
  margin-top: 1em;
}
.ButtonAnimation {
  position: relative;
  text-decoration: none;
  /* display: inline-block; */
  padding: 0.75vmax 28px;
  line-height: normal;
  border: 1px solid transparent;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 0.75vmax;
  text-align: center;
  letter-spacing: 0.2em;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-style: normal;
  background-color: transparent;
}
.ButtonAnimation::before {
  position: absolute;
  content: "";
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: scale(1, 1);
  transform-origin: left center;
  z-index: -1;
}
.ButtonAnimation:not([disabled]):hover::before {
  transform-origin: right center;
  transform: scale(0, 1);
}
.ButtonAnimation:not([disabled])::before {
  transition-property: transform;
  transition-duration: 0.45s;
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 0s;
}
#section-header .ButtonAnimation {
  color: #363636;
  border-color: #ffffff;
}
#section-header .ButtonAnimation::before {
  background-color: #ffffff;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
  #section-header .ButtonAnimation:hover {
    color: #ffffff;
  }
}
