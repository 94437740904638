@font-face {
  font-family: "Bebas Neue", sans-serif;
  src: url("/public/BebasNeue-Regular.ttf");
}
.panel {
  display: block;
  position: absolute;
  pointer-events: none;
  text-align: left;
  background-color: transparent;
  transform: translate(1vw, -68vh);
  line-height: normal;
}
.yesTouch {
  pointer-events: all;
}
.mainIntro {
  font-family: "Bebas Neue";
  font-size: 5vmax;
  font-weight: 400;
  margin: 0;
  /* pointer-events: none; */
}

.subIntro {
  font-family: "Bebas Neue";
  font-size: 3vmax;
  font-weight: 400;
  margin: 0;
}

.mainIntroMobile {
  font-family: "Bebas Neue";
  font-size: 3em;
  margin: 0;
  display: flex;
}

.subIntroMobile {
  font-family: "Bebas Neue";
  font-size: 2em;
  font-weight: bold;
  margin: 0;
  display: flex;
}

@media (max-width: 500px) {
  .mainText {
    display: none;
    pointer-events: none;
  }
  .mobileText {
    display: contents;
  }
}

@media (min-width: 501px) {
  .mainText {
    display: contents;
    pointer-events: none;
  }
  .mobileText {
    display: none;
  }
}

.textColor {
  color: #ff4141;
}
