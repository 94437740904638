.aboutSection {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  /* grid-template-columns: repeat(3, minmax(300px, 1fr)); */
  /* grid-auto-columns: 200px; */
}

/* .skillType {
  grid-column: 1;
  background-color: #1d2125;
  padding: 10px;
}

.skillList {
  grid-column: 2 / 4;
  background-color: #1d2125;
  padding: 10px;

} */

.skillSection {
  --n: 4;
  --repeat: auto-fit;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(max(200px, 45vw),4fr));
  /* grid-template-columns: repeat(var(--repeat, auto-fit), minmax(calc(250px * 1) , 1fr)); */
}

.skills {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  /* grid-auto-rows: 340px; */
}

.skillsWrapper {
  display: grid;
  grid-auto-rows: minmax(100px, auto);
  grid-template-columns: repeat(3, 1fr);
  row-gap: 10px;
  column-gap: 10px;
}
.projects{
  display: grid;
  /* grid-auto-rows: minmax(100px, auto); */
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  gap: 1rem;
}

@media (max-width: 600px) {
  

  .skillsWrapper {
    display: grid;
    grid-auto-rows: minmax(100px, auto);
    grid-template-columns: 100%;
    row-gap: 10px;
    column-gap: 10px;
  }
  .skillType {
    grid-column: 1;
    background-color: #1d2125;
    padding: 10px;
  }

  .skillList {
    grid-column: 1;
    background-color: #1d2125;
    padding: 10px;
  }

  .skillSection {
    display: grid;
    gap: 1rem;
    grid-template-columns: none;
  }
  
}

@media (max-width: 1280px) {
  /* .aboutSection {
    display: grid;
    grid-template-columns: none;
    grid-column: 1;
  } */

  /* .skillSection {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, minmax(300px, 1fr))
  } */

  /* .projects{
    display: grid;
    grid-template-columns: none;
    grid-column: 1;
    row-gap: 10px;
    } */
}
